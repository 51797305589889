import React from 'react';
import './ListTiered.scss';

/**
 * @namespace ListTiered
 * @function ListTiered
 * @author Ryan Groom
 * @since 07/11/22
 * @version 1.0.0
 * @component
 */
export default function ListTiered({ className, title, list }) {
  return (
    <div className={`List-Tiered ${className ? className : ''}`}>
      <h3>{title}</h3>
      <div className='Organizations'>
        {list.map(
          (item) =>
            item.logo && (
              <div key={item.name} className='Organization'>
                {item.website || item.phone ? (
                  <>
                    {item.website ? (
                      <a href={item.website}>
                        <img
                          src={item.logo.asset.url}
                          alt={`Logo for ${item.name}`}
                        />
                      </a>
                    ) : (
                      <a href={`tel:${item.phone}`}>
                        <img
                          src={item.logo.asset.url}
                          alt={`Logo for ${item.name}`}
                        />
                      </a>
                    )}
                  </>
                ) : (
                  <img src={item.logo.asset.url} />
                )}
              </div>
            )
        )}
      </div>
      <div className='Individuals'>
        {list.map(
          (item) =>
            !item.logo &&
            (item.website || item.phone) && (
              <div key={item.name} className='Individual'>
                {item.website ? (
                  <a href={item.website}>{item.name}</a>
                ) : (
                  <a href={`tel:${item.phone}`}>{item.name}</a>
                )}
              </div>
            )
        )}
        {list.map(
          (item) =>
            !item.logo &&
            !item.website &&
            !item.phone && (
              <div key={item.name} className='Individual'>
                <p>{item.name}</p>
              </div>
            )
        )}
      </div>
    </div>
  );
}
