import * as React from 'react';
import Banner from '../Components/Banner/Banner';
import Layout from '../Components/Layout/Layout';
import Button from '../Components/Button/Button';
import ListTiered from '../Components/ListTiered/ListTiered';
import Form from '../Components/Form/Form';
import PaymentModal from '../Components/PaymentModal/PaymentModal';
import PayPalButton from '../Components/PayPalButton/PayPalButton';
import { graphql, useStaticQuery } from 'gatsby';

import { PortableText } from '@portabletext/react';

import { getSectionSortedObject } from '../utils/getSectionSortedObject/getSectionSortedObject';
import { client } from '../utils/postData/postData';

import './index.scss';
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const initialState = {
  sponsorName: '',
  phone: '',
  email: '',
  website: '',
};
function reducer(state, action) {
  const newState = { ...state, ...action };
  return newState;
}
const tiers = ['Platinum', 'Gold', 'Silver', 'Bronze']; // Array of tier labels
const values = ['10k', '1k', '250', '100'];
// markup
const IndexPage = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const data = useStaticQuery(graphql`
    query indexQuery {
      allSanitySponsor(sort: { fields: logo___asset___assetId }) {
        nodes {
          website
          totalDonated
          name
          phone
          tier
          email
          logo {
            asset {
              url
              assetId
            }
          }
        }
      }
      allSanityAlumni {
        nodes {
          year
          name
        }
      }
      allSanityCorner {
        nodes {
          _rawCornerText
          cornerImage {
            image {
              asset {
                url
              }
            }
            alt
          }
          featuredLink {
            url
            text
          }
          golfDate
          campForm {
            asset {
              url
            }
          }
          alumniForm {
            asset {
              url
            }
          }
          _rawMailingAddress
        }
      }
    }
  `);

  const [submitted, setSubmitted] = React.useState(false);

  const sponsors = data?.allSanitySponsor?.nodes; // Array of sponsors(Object)
  const sortedSponsors = getSectionSortedObject(sponsors, 'tier'); // Object with keys of tiers containing sponsors of that tier

  const alumni = data?.allSanityAlumni?.nodes;
  const sortedAlumni = getSectionSortedObject(alumni, 'year'); // Object with keys of years containing alumni of that year
  const alumniSections = Object.keys(sortedAlumni).reverse().slice(0, 4); // Array of 4 most recent years used
  const alumni2dArray = [alumniSections.slice(0, 2), alumniSections.slice(2)]; //2D array to handle wrapper layout

  const corner = data?.allSanityCorner?.nodes?.[0]; // Coaches corner data
  console.log(corner);

  const campForm = data?.allSanityCorner?.nodes?.[0]?.campForm?.asset?.url;
  const mailAddress = data?.allSanityCorner?.nodes?.[0]?._rawMailingAddress;

  return (
    <Layout className='Home' home>
      <Banner title='Brookville Wrestling' home />
      <section className='Background-White'>
        <div className='Frame-Inner Grid Grid-L Grid-1-1 Grid-Gap-4'>
          <div className='Grid Grid-L Grid-1 Grid-Gap-2'>
            <div>
              <h2>Coach's Corner</h2>
            </div>
            <div>
              <PortableText value={corner?._rawCornerText} />
            </div>
            <div className='Column-Left'>
              <Button
                buttonText={corner?.featuredLink.text}
                onClick={corner?.featuredLink.url}
              />
            </div>
          </div>
          <div>
            <img
              src={corner?.cornerImage?.image?.asset?.url}
              alt={corner?.cornerImage?.alt}
            />
          </div>
        </div>
      </section>
      <section className='Camp-Section'>
        <div className='Overlay Overlay-White'>
          <div className='Frame-Inner Text-Center'>
            <h2 className='Text-Center'>Camp Reimbursement Form</h2>
            <p className='Text-Center'>
              The Brookville Wrestling Alumni Association will reimburse
              parents/guardians 50% up to $200 per year to attend any Brookville
              Wrestling Alumni Association approved wrestling camp. This
              opportunity is open to Brookville Wrestling Team members only.
            </p>
            <PaymentModal
              openButtonText='Reimbursement Form'
              buttonClassName='Button-Blue'
            >
              <div className='Alumni-Mail'>
                <h2>Camp Reimbursement Form</h2>
                <Button
                  onClick={campForm}
                  buttonText='Camp Reimbursement Form'
                  external
                />
                <div className='Contact'>
                  {mailAddress && (
                    <div className='MailingAddress'>
                      <PortableText value={mailAddress} />
                    </div>
                  )}
                  <div>
                    <p>Reach out if you have any questions </p>
                    <a href='tel:9375724790'>937-572-4790</a>
                  </div>
                </div>
              </div>
            </PaymentModal>
          </div>
        </div>
      </section>
      {alumni.length && (
        <section className='Background-Blue Alumni-Section'>
          <div className='Frame-Inner Grid Grid-L Grid-1 Grid-Gap-2'>
            <div>
              <h2>Brookville Wrestling Alumni</h2>
            </div>
            <div className='Grid Grid-L Grid-1-1 Grid-Gap-4'>
              {alumni2dArray.map((section, index) => (
                <div className='Grid Grid-XS Grid-1-1 Grid-Gap-4' key={index}>
                  {section.map((year) => (
                    <div key={year}>
                      <h3>{year}</h3>
                      <ul>
                        {sortedAlumni[year].map((alumni) => (
                          <li key={alumni.name}>{alumni.name}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      <section className='Background-White Sponsorship-Section'>
        <div className='Frame-Inner'>
          <div className='Grid Grid-L Grid-1 Grid-Gap-4 Grid-Center'>
            <div>
              <h2 className='Text-Center'>Our Sponsors</h2>
            </div>
            {tiers.map((tier, index) => (
              <div key={tier}>
                <ListTiered
                  className={tier}
                  title={`${tier} Sponsorships ($${values[index]}+)`}
                  list={sortedSponsors?.[tier.toLowerCase()]}
                />
              </div>
            ))}
            <PaymentModal
              openButtonText='Become a sponsor!'
              buttonClassName='Button-Blue'
            >
              {submitted ? (
                <div>Thank you for your interest in being a supporter!</div>
              ) : (
                <>
                  <h2>Become a sponsor</h2>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      const form = e.target;
                      console.log(form.getAttribute('name'));
                      console.log(
                        encode({
                          'form-name': form.getAttribute('name'),
                          ...state,
                        })
                      );
                      fetch('/', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: encode({
                          'form-name': form.getAttribute('name'),
                          ...state,
                        }),
                      })
                        .then(() => setSubmitted(true))
                        .catch((error) => alert(error));
                    }}
                    className='Sponsor-Form'
                    name='sponsor'
                    method='post'
                    data-netlify='true'
                    data-netlify-honeypot='bot-field'
                  >
                    <input type='hidden' name='form-name' value='sponsor' />
                    <label>
                      Business or Full Name
                      <span className='Required-Field'>*</span>
                      <input
                        name='sponsorName'
                        className='name'
                        type='text'
                        placeholder='John Smith'
                        onChange={(e) => {
                          dispatch({ sponsorName: e.target.value });
                        }}
                        value={state.sponsorName ?? ''}
                        required
                      />
                    </label>
                    <label>
                      Phone Number
                      <span className='Required-Field'>*</span>
                      <input
                        name='phone'
                        className='phone'
                        type='text'
                        placeholder='555-555-5555'
                        onChange={(e) => {
                          dispatch({ phone: e.target.value });
                        }}
                        value={state.phone ?? ''}
                      />
                    </label>
                    <label>
                      Email
                      <span className='Required-Field'>*</span>
                      <input
                        name='email'
                        className='email'
                        type='text'
                        placeholder='JohnSmith@email.com'
                        onChange={(e) => {
                          dispatch({ email: e.target.value });
                        }}
                        value={state.email ?? ''}
                      />
                    </label>
                    <label>
                      Website
                      <input
                        name='website'
                        className='website'
                        type='text'
                        placeholder='www.brookvillewrestling.com'
                        onChange={(e) => {
                          dispatch({ website: e.target.value });
                        }}
                        value={state.website ?? ''}
                      />
                    </label>
                    <Button
                      disabled={
                        !(state.sponsorName && state.phone && state.email)
                      }
                      submit
                      buttonText={'Submit'}
                    />
                    {/* <label>
                      Logo
                      <input
                        className='logo'
                        type='file'
                        onChange={(e) => {
                          dispatch({
                            logo: URL.createObjectURL(e.target.files[0]),
                          });
                        }}
                      />
                    </label> */}
                    {/* <label>
                      Donation Amount<span className='Required-Field'>*</span>
                      <input
                        className='amount'
                        type='text'
                        placeholder='5'
                        onChange={(e) => {
                          dispatch({ amount: e.target.value });
                        }}
                        value={state.amount ?? ''}
                        required
                      />
                    </label> */}
                  </form>
                  {/* {state.amount && state.name && (
                    <PayPalButton
                      amount={state.amount}
                      onApprove={async () => {
                        const obj = {
                          name: document.querySelector('.Sponsor-Form .name'),
                          phone: document.querySelector('.Sponsor-Form .phone'),
                          email: document.querySelector('.Sponsor-Form .email'),
                          website: document.querySelector(
                            '.Sponsor-Form .website'
                          ),
                          logo: document.querySelector('.Sponsor-Form .logo'),
                        };
                        for (const key in obj) {
                          const element = obj[key];
                          if (element.type === 'file') {
                            obj[key] = URL.createObjectURL(element?.files?.[0]);
                          } else {
                            obj[key] = element.value;
                          }
                        }
                        const query =
                          '*[_type == "sponsor"] {_id, name, totalDonated}';
                        let id;
                        let totalDonated = Number(state.amount);
                        await client.fetch(query).then((alumnis) => {
                          alumnis.forEach((alumni) => {
                            if (alumni.name === obj.name) {
                              id = alumni._id;
                              totalDonated += Number(alumni.totalDonated);
                            }
                          });
                        });

                        let tier;
                        switch (true) {
                          case totalDonated >= 10000:
                            tier = 'platinum';
                            break;
                          case totalDonated >= 1000:
                            tier = 'gold';
                            break;
                          case totalDonated >= 250:
                            tier = 'silver';
                            break;
                          case totalDonated >= 100:
                            tier = 'bronze';
                            break;
                          default:
                            tier = 'none';
                            break;
                        }
                        if (id) {
                          client
                            .patch(id) // Document ID to patch
                            .set({
                              name: obj.name,
                              phone: obj.phone,
                              email: obj.email,
                              website: obj.website,
                              logo: obj.logo,
                              tier: tier,
                              totalDonated: totalDonated,
                            })
                            .commit() // Perform the patch and return a promise
                            .then((updatedItem) => {
                              console.log(
                                'Hurray, the Sponsor is updated! New document:'
                              );
                              console.log(updatedItem);
                            })
                            .catch((err) => {
                              console.error(
                                'Oh no, the update failed: ',
                                err.message
                              );
                            });
                        } else {
                          const doc = {
                            _type: 'sponsor',
                            name: obj.name,
                            phone: obj.phone,
                            email: obj.email,
                            website: obj.website,
                            logo: obj.logo,
                            tier: tier,
                            totalDonated: totalDonated,
                          };

                          client.create(doc).then((res) => {
                            console.log(
                              `Alumni was created, document ID is ${res._id}`
                            );
                          });
                        }
                        dispatch({ alumniSuccess: true });
                      }}
                      onError={() => {}}
                    />
                  )} */}
                </>
              )}
            </PaymentModal>
          </div>
        </div>
      </section>
      <section className='Background-Blue Contact-Section'>
        <div className='Smaller-Frame Grid Grid-L Grid-1 Grid-Gap-4 Grid-Center'>
          <div className='Text-Center'>
            <h2>Get In Touch</h2>
            <p>
              If you have a question or would like more information feel free to
              use the contact form and we will get back with you via email.
            </p>
          </div>
          <div>
            <Form />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
